import { defineType } from 'sanity';
import React from 'react';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Image from '@/components/Image';
import Link from 'next/link';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';

export interface IBlock07 {
  items: {
    image: {
      src: string;
      aspectRatio: string;
    };
    title: string;
    description: string;
    link: {
      href: string;
      text: string;
    };
  }[];
  title: string;
}

export default function Block07({ items, title }: IBlock07) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      <Typography variant='h1' className='title-underlined'>
        {title}
      </Typography>

      <Grid
        container
        spacing={{
          xs: 2,
          md: 4,
        }}
        sx={{
          alignItems: 'flex-start',
          pt: {
            xs: 2,
            md: 4,
          },
        }}
      >
        {items.map(({ image, title, description, link }, index) => {
          const featured = index === 0;

          return (
            <Grid
              size={{
                xs: 12,
                md: featured ? 12 : 6,
              }}
              key={index}
              container
              spacing={featured ? 4 : 2}
            >
              <Grid
                size={{
                  xs: 12,
                  md: featured ? 8 : 12,
                }}
              >
                <Link href={link}>
                  <Image
                    src={image.src}
                    alt={title}
                    sx={{
                      width: '100%',
                    }}
                    aspectRatio={featured ? '4/3' : '1/1'}
                    sizes={
                      featured
                        ? '(max-width: 1536px) 100vw, 1536px'
                        : '(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 768px'
                    }
                    objectFit='cover'
                  />
                </Link>
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  md: featured ? 4 : 12,
                }}
              >
                <Stack direction='column' spacing={2} py={2}>
                  <Typography variant='h2'>{title}</Typography>
                  <Typography variant='h6'>{description}</Typography>
                  {link && (
                    <div>
                      <MuiLink href={link} component={Link} className='link-underlined'>
                        Read more
                      </MuiLink>
                    </div>
                  )}
                </Stack>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

const componentName = 'Projects List · Block 07';

export const block07 = defineType({
  type: 'object',
  name: 'block07',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
    },

    {
      name: 'items',
      title: 'Items',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'project' }],
        },
      ],
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image',
      subtitle: 'description',
    },
    prepare({ title, subtitle, media }) {
      return {
        title,
        subtitle: subtitle || componentName,
        media,
      };
    },
  },
});
