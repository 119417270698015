import { PortableText as PortableTextSanity, PortableTextBlock } from '@portabletext/react';
import Link from 'next/link';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import TitleWithLine from './TitleWithLine';
import Box from '@mui/material/Box';
import Block01, { IBlock01 } from '@/components/blocks/Block01';
import Block02, { IBlock02 } from '@/components/blocks/Block02';
import Block03, { IBlock03 } from '@/components/blocks/Block03';
import Block04, { IBlock04 } from '@/components/blocks/Block04';
import Block05, { IBlock05 } from '@/components/blocks/Block05';
import Block06, { IBlock06 } from '@/components/blocks/Block06';
import Block07, { IBlock07 } from '@/components/blocks/Block07';
import Block08, { IBlock08 } from '@/components/blocks/Block08';
import Block09, { IBlock09 } from '@/components/blocks/Block09';
import Block10, { IBlock10 } from '@/components/blocks/Block10';
import Block11, { IBlock11 } from '@/components/blocks/Block11';
import Block12, { IBlock12 } from '@/components/blocks/Block12';
import Block13, { IBlock13 } from '@/components/blocks/Block13';

interface IPortableText {
  blocks: PortableTextBlock[];
}

interface IBlockProps {
  index: number;
}

export default function PortableText({ blocks = [] }: IPortableText) {
  return (
    <div style={{ flex: 1 }}>
      <PortableTextSanity
        value={blocks}
        components={{
          types: {
            span: ({ children }: any) => <span>{children}</span>,
            image: ({ value }: any) => (
              <Image
                src={value.asset._ref}
                alt={value.alt || ''}
                width={value.width || 500}
                height={value.height || 300}
                layout='responsive'
                style={{
                  paddingTop: 'calc(var(--mui-spacing) * 2)',
                  paddingBottom: 'calc(var(--mui-spacing) * 2)',
                }}
              />
            ),
            block01: ({ value, index }: { value: IBlock01 } & IBlockProps) => (
              <Block01 {...value} index={index} />
            ),
            block02: ({ value }: { value: IBlock02 }) => <Block02 {...value} />,
            block03: ({ value }: { value: IBlock03 }) => <Block03 {...value} />,
            block04: ({ value }: { value: IBlock04 }) => <Block04 {...value} />,
            block05: ({ value }: { value: IBlock05 }) => <Block05 {...value} />,
            block06: ({ value }: { value: IBlock06 }) => <Block06 {...value} />,
            block07: ({ value }: { value: IBlock07 }) => <Block07 {...value} />,
            block08: ({ value }: { value: IBlock08 }) => <Block08 {...value} />,
            block09: ({ value }: { value: IBlock09 }) => <Block09 {...value} />,
            block10: ({ value }: { value: IBlock10 }) => <Block10 {...value} />,
            block11: ({ value }: { value: IBlock11 }) => <Block11 {...value} />,
            block12: ({ value }: { value: IBlock12 }) => <Block12 {...value} />,
            block13: ({ value }: { value: IBlock13 }) => <Block13 {...value} />,
          },
          marks: {
            link: ({ value, children }: any) => {
              const { href, blank, underline } = value;
              return (
                <MuiLink
                  href={href}
                  target={blank ? '_blank' : undefined}
                  component={Link}
                  className={underline ? 'link-underlined' : ''}
                >
                  {children}
                </MuiLink>
              );
            },
          },
          block: {
            h1: ({ children }: any) => <TitleWithLine variant='h2'>{children}</TitleWithLine>,
            h2: ({ children }: any) => <TitleWithLine variant='h2'>{children}</TitleWithLine>,
            h3: ({ children }: any) => <TitleWithLine variant='h3'>{children}</TitleWithLine>,
            h4: ({ children }: any) => (
              <Typography variant='h4' sx={{ py: 2 }}>
                {children}
              </Typography>
            ),
            h5: ({ children }: any) => (
              <Typography variant='h5' sx={{ py: 2 }}>
                {children}
              </Typography>
            ),
            h6: ({ children }: any) => (
              <Typography variant='h6' sx={{ py: 2 }}>
                {children}
              </Typography>
            ),
            normal: ({ children }: any) => (
              <Typography
                variant='body1'
                sx={{
                  py: 1.25,
                }}
              >
                {children}
              </Typography>
            ),
            blockquote: ({ children }: any) => (
              <Box
                sx={{
                  borderLeft: '4px solid',
                  borderColor: 'primary.main',
                  pl: 2,
                  py: 1,
                  my: 2,
                  ml: 2,
                }}
              >
                <Typography variant='h5' component='blockquote'>
                  {children}
                </Typography>
              </Box>
            ),
          },
          list: {
            bullet: ({ children }: any) => (
              <ul
                style={{
                  paddingRight: 'calc(var(--mui-spacing) * 2)',
                }}
              >
                {children}
              </ul>
            ),
            number: ({ children }: any) => (
              <ol
                style={{
                  paddingRight: 'calc(var(--mui-spacing) * 2)',
                }}
              >
                {children}
              </ol>
            ),
          },
          listItem: {
            bullet: ({ children }: any) => <li>{children}</li>,
            number: ({ children }: any) => <li>{children}</li>,
          },
        }}
      />
    </div>
  );
}
