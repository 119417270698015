import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function TitleWithLine({
  variant,
  children,
}: {
  variant: 'h1' | 'h2' | 'h3';
  children: React.ReactNode;
}) {
  return (
    <>
      <Typography variant={variant} sx={{ py: 2 }}>
        {children}
      </Typography>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'common.black',
          width: 100,
          mt: 1,
          mb: 2,
        }}
      />
    </>
  );
}
