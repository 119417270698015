import { defineType } from 'sanity';
import { PortableTextBlock } from '@portabletext/react';
import React from 'react';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import PortableText from '@/components/PortableText';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export interface IBlock10 {
  contentLeft: PortableTextBlock[];
  contentRight: PortableTextBlock[];
  mainContent: PortableTextBlock[];
  title?: string;
}

export default function Block10({ contentLeft, contentRight, mainContent, title }: IBlock10) {
  return (
    <Container maxWidth='lg'>
      <Stack
        direction='column'
        spacing={4}
        sx={{
          my: 4,
        }}
      >
        {title && (
          <div>
            <Typography variant='h1' className='title-underlined'>
              {title}
            </Typography>
          </div>
        )}

        {mainContent?.length > 0 && <PortableText blocks={mainContent} />}

        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={6}
        >
          {contentLeft?.length > 0 && <PortableText blocks={contentLeft} />}
          {contentRight?.length > 0 && <PortableText blocks={contentRight} />}
        </Stack>
      </Stack>
    </Container>
  );
}

const componentName = 'Article · Block 10';

export const block10 = defineType({
  type: 'object',
  name: 'block10',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'title',
      type: 'string',
      title: 'Title',
    },
    {
      name: 'mainContent',
      type: 'blockContent',
      title: 'Main Content',
    },
    {
      name: 'contentLeft',
      type: 'blockContent',
      title: 'Content Left',
    },
    {
      name: 'contentRight',
      type: 'blockContent',
      title: 'Content Right',
    },
  ],
  preview: {
    select: {
      title: 'title',
      contentLeft: 'contentLeft',
      contentRight: 'contentRight',
    },
    prepare: ({ title, contentLeft, contentRight }) => ({
      title: title || componentName,
      subtitle: `${contentLeft?.length ?? 0} left, ${contentRight?.length ?? 0} right`,
    }),
  },
});
