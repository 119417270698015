'use client';

import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import Collapse from '@mui/material/Collapse';
import CardActionArea from '@mui/material/CardActionArea';
import PortableText from '@/components/PortableText';
import type { PortableTextBlock } from '@portabletext/react';
import Box from '@mui/material/Box';

export interface IQuestion {
  question: string;
  answer: PortableTextBlock[];
}

export default function Question({ question, answer }: IQuestion) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <CardActionArea
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 1,
          py: 0.5,
          px: 0.25,
        }}
      >
        {open ? <RemoveIcon /> : <AddIcon />}
        <Typography variant='h4'>{question}</Typography>
      </CardActionArea>

      <Collapse in={open}>
        <Box sx={{ py: 1, px: 3.25 }}>
          <PortableText blocks={answer} />
        </Box>
      </Collapse>
    </div>
  );
}
