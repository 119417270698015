import { defineType } from 'sanity';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import Link from 'next/link';
import Stack from '@mui/material/Stack';
import Image from '@/components/Image';

export interface IBlock01 {
  title: string;
  description: string;
  image: {
    src: string;
    aspectRatio: string;
  };
  textColor: string;
  link?: {
    text: string;
    href: string;
  };
  index: number;
}

export default function Block01({ title, description, textColor, image, link, index }: IBlock01) {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: 'relative',
        mt: index === 0 ? 0 : 3,
        mb: 3,
      }}
    >
      <Image
        alt={title}
        sizes='100vw'
        src={image.src}
        aspectRatio={{
          xs: '9/16',
          sm: image.aspectRatio,
        }}
      />

      <Stack
        direction='row'
        sx={{
          width: '100%',
          position: 'absolute',
          maxWidth: 'lg',
          top: 0,
          left: 0,
          right: 0,
          mx: 'auto',
          bottom: 0,
          zIndex: 1,
          p: {
            xs: 2,
            md: 3,
          },
        }}
      >
        <Stack
          direction='column'
          spacing={1}
          sx={{
            maxWidth: {
              xs: '100%',
              md: 'sm',
            },
            color: textColor,

            zIndex: 1,
          }}
        >
          {title && (
            <Typography
              variant='h2'
              sx={{
                fontSize: {
                  xs: '1.75rem',
                  md: '2rem',
                },
              }}
            >
              {title}
            </Typography>
          )}

          {description && <Typography variant='body1'>{description}</Typography>}

          {link && link.href && (
            <div>
              <MuiLink
                component={Link}
                href={link.href}
                className='link-underlined'
                color='inherit'
              >
                {link.text}
              </MuiLink>
            </div>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}

const componentName = 'Full Image · Block 01';

export const block01 = defineType({
  type: 'object',
  name: 'block01',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'image',
      title: 'Image',
      type: 'image',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      rows: 4,
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      rows: 4,
    },
    {
      name: 'textColor',
      title: 'Text Color',
      type: 'string',
      initialValue: 'common.white',
      options: {
        list: [
          { title: 'White', value: 'common.white' },
          { title: 'Black', value: 'common.black' },
        ],
      },
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'link',
      title: 'Link',
      type: 'link',
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image',
      subtitle: 'description',
    },
    prepare({ title, subtitle, media }) {
      return {
        title: title ?? 'Untitled',
        subtitle: subtitle ?? componentName,
        media,
      };
    },
  },
});
