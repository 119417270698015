import { defineType } from 'sanity';
import { PortableTextBlock } from '@portabletext/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Image from 'next/image';
import CategoryIcon from '@mui/icons-material/Category';
import PortableText from '@/components/PortableText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';

interface IFormField {
  name: string;
  label?: string;
  type: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
}

export interface IBlock11 {
  title: string;
  description: string;
  content: PortableTextBlock[];
  image: {
    src: string;
    aspectRatio: string;
  };
  formFields: IFormField[];
  returnUrl: string;
  organizationId: string;
  leadSource: string;
}

export default function Block11({
  title,
  description,
  image,
  content,
  formFields,
  returnUrl,
  organizationId,
  leadSource,
}: IBlock11) {
  const fields: IFormField[] = useMemo(
    () => [
      { name: 'oid', value: organizationId, type: 'hidden' },
      { name: 'retURL', value: returnUrl, type: 'hidden' },
      { name: 'lead_source', value: leadSource, type: 'hidden' },
      ...formFields,
    ],
    [organizationId, returnUrl, leadSource, formFields],
  );

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'stretch',
          width: '100%',
          mx: 'auto',
          height: '100%',
        }}
      >
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <Image
              src={image.src}
              alt={title}
              sizes='(max-width: 1024px) 100vw, (max-width: 1536px) 50vw'
              objectFit='cover'
              objectPosition='center'
              fill
            />
          </Box>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Box
            sx={{
              maxWidth: {
                xs: '100%',
                md: 768,
              },
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              direction='column'
              spacing={2}
              sx={{
                maxWidth: {
                  xs: '100%',
                  md: 520,
                },
                m: 2,
              }}
            >
              <Typography variant='h2'>{title}</Typography>
              <Typography variant='body2'>{description}</Typography>

              <form
                action={`https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=${organizationId}`}
                method='POST'
              >
                {fields.map(
                  ({ name, label, value, type = 'text', required = false, placeholder }) => {
                    if (type === 'hidden')
                      return <input key={name} type={type} name={name} value={value} />;

                    return (
                      <TextField
                        key={name}
                        label={label}
                        name={name}
                        value={value}
                        required={required}
                        variant='standard'
                        margin='normal'
                        fullWidth
                        type={type}
                        size='small'
                        placeholder={placeholder}
                      />
                    );
                  },
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
                  <Button type='submit' variant='contained'>
                    Submit
                  </Button>
                </Box>
              </form>
              <Box>
                <PortableText blocks={content} />
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const componentName = 'Form · Block 11';

export const block11 = defineType({
  type: 'object',
  name: 'block11',
  title: componentName,
  icon: CategoryIcon,
  fields: [
    {
      name: 'title',
      type: 'string',
      title: 'Title',
    },
    {
      name: 'description',
      type: 'text',
      title: 'Description',
    },
    {
      name: 'image',
      type: 'image',
      title: 'Image',
    },
    {
      name: 'content',
      type: 'blockContent',
      title: 'Content',
    },
    {
      name: 'returnUrl',
      type: 'url',
      title: 'Return URL',
      description: 'The URL to redirect to after the form is submitted',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'organizationId',
      type: 'string',
      title: 'Organization ID',
      description: 'The ID of the organization to use for the form',
      initialValue: '00D90000000jXZH',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'leadSource',
      type: 'string',
      title: 'Lead Source',
      description: 'The source of the lead',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'formFields',
      type: 'array',
      title: 'Form Fields',
      description: 'The fields to display in the form',
      of: [{ type: 'formField' }],
      validation: (Rule) => Rule.required(),
    },
  ],
  preview: {
    select: {
      title: 'title',
      description: 'description',
      formFields: 'formFields',
    },
    prepare: ({ title, description, formFields = [] }) => ({
      title: title || 'Contact Form',
      subtitle: `${formFields.length} form fields${description ? ' · ' + description : ''}`,
    }),
  },
});
