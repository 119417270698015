import { defineType } from 'sanity';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Image from '@/components/Image';

export interface IBlock06 {
  title?: string;
  image?: {
    src?: string;
    aspectRatio?: string;
  };
}

export default function Block06({ title, image }: IBlock06) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      {image?.src && (
        <Image
          alt={title ?? ''}
          src={image.src}
          aspectRatio={image.aspectRatio}
          sizes='(max-width: 1536px) 100vw, 1536px'
        />
      )}
    </Container>
  );
}

const componentName = 'Single Image · Block 06';

export const block06 = defineType({
  type: 'object',
  name: 'block06',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'image',
      title: 'Image',
      type: 'image',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'title',
      title: 'Alt Text',
      type: 'string',
      description: 'The alt text of the image.',
      validation: (Rule) => Rule.required(),
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image',
    },
    prepare({ title, media }) {
      return {
        title: title ?? 'Untitled',
        subtitle: componentName,
        media,
      };
    },
  },
});
