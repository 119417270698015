import { defineType } from 'sanity';
import { PortableTextBlock } from '@portabletext/react';
import Grid from '@mui/material/Grid';
import CategoryIcon from '@mui/icons-material/Category';
import PortableText from '@/components/PortableText';
import Container from '@mui/material/Container';

export interface IBlock13 {
  location: {
    lat: number;
    lng: number;
  };
  content: PortableTextBlock[];
}

export default function Block13({ location, content }: IBlock13) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'stretch',
          width: '100%',
          mx: 'auto',
          height: '100%',
        }}
      >
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
          sx={{
            display: 'flex',
          }}
        >
          <PortableText blocks={content} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <iframe
            width='100%'
            height='100%'
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS}&q=${location?.lat},${location?.lng}&zoom=15`}
            allowFullScreen
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        </Grid>
      </Grid>
    </Container>
  );
}

const componentName = 'Location · Block 13';

export const block13 = defineType({
  type: 'object',
  name: 'block13',
  title: componentName,
  icon: CategoryIcon,
  fields: [
    {
      name: 'location',
      type: 'geopoint',
      title: 'Location',
    },
    {
      name: 'content',
      type: 'blockContent',
      title: 'Content',
    },
  ],
  preview: {
    select: {
      location: 'location',
      content: 'content',
    },
    prepare: ({ location }) => ({
      title: componentName,
      subtitle: `${location?.lat}, ${location?.lng}`,
    }),
  },
});
