import React from 'react';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import QuestionIcon from '@mui/icons-material/HelpOutline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { defineType } from 'sanity';
import Question, { IQuestion } from './Question';

export interface IBlock08 {
  title?: string;
  showTitle?: boolean;
  questions?: IQuestion[];
}

export default function Block08({ title, showTitle = true, questions = [] }: IBlock08) {
  return (
    <Container maxWidth='lg'>
      {showTitle && title && (
        <Typography
          variant='h1'
          gutterBottom
          sx={{
            my: 4,
          }}
        >
          {title}
        </Typography>
      )}

      <Stack direction='column' spacing={1}>
        {questions.map(({ question, answer }, index) => (
          <Question key={index} question={question} answer={answer} />
        ))}
      </Stack>
    </Container>
  );
}

const componentName = 'Questions · Block 08';

export const block08 = defineType({
  type: 'object',
  name: 'block08',
  title: componentName,
  icon: BlockIcon,
  initialValue: {
    showTitle: true,
    questions: [],
  },
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
    },
    {
      name: 'showTitle',
      title: 'Show Title',
      type: 'boolean',
    },
    {
      name: 'questions',
      title: 'Questions',
      type: 'array',
      of: [
        {
          type: 'object',
          icon: QuestionIcon,
          fields: [
            {
              name: 'question',
              title: 'Question',
              type: 'string',
              validation: (Rule) => Rule.required(),
            },
            {
              name: 'answer',
              title: 'Answer',
              type: 'blockContent',
              validation: (Rule) => Rule.required(),
            },
          ],
        },
      ],
    },
  ],
  preview: {
    select: {
      title: 'title',
      subtitle: 'questions',
    },
    prepare({ title, subtitle }) {
      return {
        title: title || 'Untitled',
        subtitle: `${(subtitle || []).length} question(s)`,
      };
    },
  },
});
