import { defineType } from 'sanity';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CategoryIcon from '@mui/icons-material/Category';
import ItemCard, { IItemCard } from './common/ItemCard';

export interface IBlock12 {
  title: string;
  items?: IItemCard[];
}

export default function Block12({ title, items = [] }: IBlock12) {
  return (
    <Container maxWidth='lg' sx={{ bgcolor: 'divider', py: 3, my: 3 }}>
      <Typography variant='h2' className='title-underlined'>
        {title}
      </Typography>

      <Grid container spacing={2} sx={{ pt: 2 }}>
        {items?.map((item) => (
          <Grid
            key={item.id}
            size={{
              xs: 6,
              md: 4,
            }}
          >
            <ItemCard {...item} aspectRatio='3/4' imageAspectRatio='3/4' objectFit='cover' />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
const componentName = 'Categories featured · Block 12';

export const block12 = defineType({
  type: 'object',
  name: 'block12',
  title: componentName,
  icon: CategoryIcon,
  fields: [
    {
      name: 'title',
      type: 'string',
      title: 'Title',
    },
    {
      name: 'items',
      title: 'Items',
      type: 'array',
      of: [
        {
          type: 'object',
          fields: [
            {
              name: 'image',
              type: 'image',
            },
            {
              name: 'item',
              type: 'reference',
              to: [{ type: 'product' }, { type: 'collection' }, { type: 'page' }],
            },
          ],
          preview: {
            select: {
              title: 'item.seo.title',
              media: 'image',
            },
          },
        },
      ],
    },
  ],
  preview: {
    select: {
      title: 'title',
    },
    prepare: ({ title }) => ({
      title: title || 'No title',
      subtitle: componentName,
    }),
  },
});
