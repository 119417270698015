import { defineType } from 'sanity';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Image from '@/components/Image';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Link from 'next/link';

export interface IBlock03 {
  title: string;
  description: string;
  mainImage: {
    src: string;
    aspectRatio: string;
  };
  secondaryImage: {
    src: string;
    aspectRatio: string;
  };
  textPosition: 'left' | 'right';
  imagePosition: 'top' | 'bottom';
  link?: {
    text: string;
    href: string;
  };
}

export default function Block03({
  title,
  description,
  mainImage,
  secondaryImage,
  imagePosition,
  textPosition,
  link,
}: IBlock03) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      <Grid
        container
        spacing={{
          xs: 6,
          lg: 8,
        }}
        direction={{
          xs: 'column',
          md: textPosition === 'right' ? 'row' : 'row-reverse',
        }}
      >
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Image
            alt={title}
            sizes='(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 1024px'
            {...secondaryImage}
          />
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction='column'
            spacing={2}
            sx={{
              maxWidth: '100%',
              color: 'text.primary',
            }}
          >
            {imagePosition == 'top' && (
              <Image
                alt={title}
                {...mainImage}
                sizes='(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 1024px'
              />
            )}

            {title && <Typography variant='h2'>{title}</Typography>}

            {description && <Typography variant='h6'>{description}</Typography>}

            {link?.text && link?.href && (
              <div>
                <MuiLink component={Link} href={link.href} className='link-underlined'>
                  {link.text}
                </MuiLink>
              </div>
            )}

            {imagePosition === 'bottom' && (
              <Image
                alt={title}
                {...mainImage}
                sizes='(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 1024px'
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

const componentName = 'Collage · Block 03';

export const block03 = defineType({
  type: 'object',
  name: 'block03',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'mainImage',
      title: 'Main Image',
      type: 'image',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'secondaryImage',
      title: 'Secondary Image',
      type: 'image',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'textPosition',
      title: 'Text Position',
      type: 'string',
      options: {
        list: [
          { title: 'Left', value: 'left' },
          { title: 'Right', value: 'right' },
        ],
      },
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'imagePosition',
      title: 'Image Position',
      type: 'string',
      options: {
        list: [
          { title: 'Top', value: 'top' },
          { title: 'Bottom', value: 'bottom' },
        ],
      },
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      rows: 4,
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      rows: 4,
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'link',
      title: 'Link',
      type: 'link',
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'mainImage',
      subtitle: 'description',
    },
    prepare({ title, subtitle, media }) {
      return {
        title: title || 'Untitled',
        subtitle: subtitle || componentName,
        media,
      };
    },
  },
});
