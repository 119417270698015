import { defineType } from 'sanity';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Image from '@/components/Image';
import Typography from '@mui/material/Typography';

export interface IBlock05 {
  title?: string;
  image?: {
    src?: string;
    aspectRatio?: string;
  };
}

export default function Block05({ title, image }: IBlock05) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      {title && (
        <Typography variant='h2' className='title-underlined'>
          {title}
        </Typography>
      )}

      {image?.src && (
        <Image
          alt={title ?? 'Block 05 Image'}
          src={image.src}
          aspectRatio={image.aspectRatio}
          sizes='(max-width: 1536px) 100vw, 1536px'
          sx={{
            mt: 3,
          }}
        />
      )}
    </Container>
  );
}

const componentName = 'Header · Block 05';

export const block05 = defineType({
  type: 'object',
  name: 'block05',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
    },
    {
      name: 'image',
      title: 'Image',
      type: 'image',
      options: {
        hotspot: true,
      },
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image',
    },
    prepare({ title, media }) {
      return {
        title: title ?? 'Untitled',
        subtitle: componentName,
        media: media ?? BlockIcon,
      };
    },
  },
});
