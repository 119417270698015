import { defineType } from 'sanity';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ItemCard, { IItemCard } from './common/ItemCard';

export interface IBlock04 {
  title: string;
  items?: IItemCard[];
}

export default function Block04({ title, items = [] }: IBlock04) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      <Typography variant='h1' className='title-underlined'>
        {title}
      </Typography>

      <Grid container spacing={3} sx={{ pt: 3 }}>
        {items.map((item) => (
          <Grid
            key={item.id}
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <ItemCard {...item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

const componentName = 'Item list · Block 04';

export const block04 = defineType({
  type: 'object',
  name: 'block04',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'type',
      title: 'Type',
      type: 'string',
      options: {
        list: [
          { title: 'Fixed', value: 'fixed' },
          { title: 'Products by collection', value: 'collection' },
        ],
      },
      validation: (Rule) => Rule.required(),
    },
    {
      name: 'items',
      title: 'Items',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'product' }, { type: 'page' }, { type: 'collection' }],
        },
      ],
      hidden: ({ parent }: { parent: { type?: string } }) => parent?.type !== 'fixed',
    },
    {
      name: 'collection',
      title: 'Collection',
      type: 'reference',
      to: [{ type: 'collection' }],
      hidden: ({ parent }: { parent: { type?: string } }) => parent?.type !== 'collection',
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image',
      subtitle: 'description',
    },
    prepare({ title, subtitle, media }) {
      return {
        title: title ?? 'Untitled',
        subtitle: subtitle || componentName,
        media,
      };
    },
  },
});
