import { defineType } from 'sanity';
import BlockIcon from '@mui/icons-material/CategoryOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Image from '@/components/Image';
import Grid from '@mui/material/Grid';

export interface IBlock02 {
  title?: string;
  description?: string;
  image?: {
    src?: string;
    aspectRatio?: string;
  };
  imagePosition?: 'left' | 'right';
}

export default function Block02({ title, description, image, imagePosition = 'left' }: IBlock02) {
  return (
    <Container maxWidth='lg' sx={{ py: 3 }}>
      <Grid
        container
        sx={{ width: '100%' }}
        direction={{
          xs: 'column',
          md: imagePosition === 'left' ? 'row' : 'row-reverse',
        }}
        spacing={{
          xs: 4,
          md: 8,
        }}
      >
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {image?.src && (
            <Image
              alt={title ?? ''}
              src={image.src}
              aspectRatio={image.aspectRatio}
              sizes='(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 1024px'
            />
          )}
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 3,
          }}
        >
          <Stack
            direction='column'
            spacing={4}
            sx={{
              maxWidth: {
                xs: '100%',
                md: 'sm',
              },
              color: 'text.primary',
            }}
          >
            {title && <Typography variant='h1'>{title}</Typography>}

            {title && (
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'common.black',
                  width: 100,
                }}
              />
            )}

            {description && <Typography variant='body1'>{description}</Typography>}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

const componentName = '1/2 Image · Block 02';

export const block02 = defineType({
  type: 'object',
  name: 'block02',
  title: componentName,
  icon: BlockIcon,
  fields: [
    {
      name: 'image',
      title: 'Image',
      type: 'image',
    },
    {
      name: 'imagePosition',
      title: 'Image Position',
      type: 'string',
      options: {
        list: [
          { title: 'Left', value: 'left' },
          { title: 'Right', value: 'right' },
        ],
      },
      initialValue: 'left',
    },
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      rows: 4,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      rows: 4,
    },
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image',
      subtitle: 'description',
    },
    prepare({ title, subtitle, media }) {
      return {
        title: title ?? 'Untitled',
        subtitle: subtitle ?? componentName,
        media,
      };
    },
  },
});
