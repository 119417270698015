import Box from '@mui/material/Box';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import Image from '@/components/Image';
import Skeleton from '@mui/material/Skeleton';
import Price from '@/components/Price';

export interface IItemCard {
  id: string;
  title?: string;
  subtitle?: string;
  image?: {
    aspectRatio: string;
    src: string;
  };
  link?: string;
  price?: number;
  discount?: number;
  aspectRatio?: string;
  objectFit?: string;
  imageAspectRatio?: string;
}

export default function ItemCard({
  title = '',
  subtitle = '',
  image,
  price = 0,
  discount = 0,
  link = '#',
  aspectRatio = '19/22',
  objectFit = 'contain',
  imageAspectRatio,
}: IItemCard) {
  return (
    <>
      <Box
        component={Link}
        href={link}
        sx={{
          mb: 1.5,
          display: 'flex',
          aspectRatio,
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#f2f2f2',
        }}
      >
        {image ? (
          <Image
            aspectRatio={imageAspectRatio || image.aspectRatio}
            src={image.src}
            alt={title || 'Product image'}
            objectFit={objectFit as any}
            sizes='(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 768px'
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{
              aspectRatio,
              width: '100%',
              height: 'auto',
            }}
          />
        )}
      </Box>

      <Typography variant='h4' gutterBottom>
        {title || 'Untitled'}
      </Typography>

      {!!subtitle && <Typography variant='body2'>{subtitle}</Typography>}
      {!!price && <Price price={price} discount={discount} variant='body2' showFrom />}
    </>
  );
}
